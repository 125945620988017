import React from "react";
import { features } from "./Data";
import epts from "../images/epts.webp";
import sbkts from "../images/sbkts.webp";
import lawIcon from "../images/icons/law.svg";

export const Features = () => {
  return (
    <section id="epts">
      <div className="container">
        <div className="features-list">
          <ul>
            { features.map((feature) => {
              return (
                <li key={ feature.id }>
                  <img className={ "features-list__icon" } src={ lawIcon } alt="law-icon" />
                  <h1>{ feature.header }</h1>
                  <div className={ "features-list__images" }>
                    <img src={ epts } alt={ "epts" } />
                    <img src={ sbkts } alt={ "sbkts" } />
                  </div>
                  <div className={ "features-list__description" }>
                    <p>
                      { feature.text }
                    </p>
                    <p>
                      { feature.contact } <a href={ feature.hrefTel }><b>{ feature.tel }</b></a>
                    </p>
                  </div>
                </li>
              );
            }) }
          </ul>
        </div>
      </div>
    </section>
  );
};
