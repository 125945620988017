import React from "react";
import doc2 from "../images/files/doc2.webp";
import { ZoomableImage } from "react-rainbow-components";
import doc1 from "../images/files/doc1.webp";

export const Accreditation = () => {
  return (
    <section id="certificate">
      <div className="container">
        <div className="certificate">
          <h1>Аттестат об аккредитации</h1>
          <hr />
          <ul>
            <ZoomableImage src={ doc1 } alt="doc1" width="264" height="380" />
            <ZoomableImage src={ doc2 } alt="doc2" width="264" height="380" />
          </ul>
        </div>
      </div>
    </section>
  );
};