import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import person from "../images/icons/person.svg";
import { publicKey, serviceId, siteKey, templateId } from "../env";
import ReCAPTCHA from "react-google-recaptcha";

export const SendForm = ({setIsOpen, submitComplete}) => {
  const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);

  const [ fio, setFio ] = useState("");
  const [ phone, setPhone ] = useState("");

  const [ fioDirty, setFioDirty ] = useState(false);
  const [ phoneDirty, setPhoneDirty ] = useState(false);

  const [ fioError, setFioError ] = useState("Заполните ФИО");
  const [ phoneError, setPhoneError ] = useState("Заполните номер телефона");

  const [ formValid, setFormValid ] = useState(false);
  const [ submitValue, setSubmitValue ] = useState("");

  const [ fioValid, setFioValid ] = useState("");
  const [ phoneValid, setPhoneValid ] = useState("");

  const classInput = "send-form__input";
  const classInputError = "send-form__input-error";
  const classValidInput = " valid";
  const classError = "send-form__error";

  useEffect(() => {
    if (
      fioError ||
      phoneError ||
      !isCaptchaSuccessful
    ) {
      setFormValid(false);

      if (window.innerWidth > 375) {
        setSubmitValue(
          "Вы не заполнили все обязательные поля выше."
        );
      } else {
        setSubmitValue("Заполните все поля.");
      }

    } else if (!isCaptchaSuccessful){
      setFormValid(false);
      setSubmitValue("Подтвердите капчу!");
    }else {
      setFormValid(true);
      setSubmitValue("Отправить заявку");
    }
  }, [
    fioError,
    phoneError,
    isCaptchaSuccessful
  ]);

  const form = useRef();

  const fioHandler = (e) => {
    setFio(e.target.value);
    const re =
      /^([А-ЯA-Z]|[А-ЯA-Z][\x27а-яa-z]+|[А-ЯA-Z][\x27а-яa-z]+-([А-ЯA-Z][\x27а-яa-z]+|(оглы)|(кызы)))\040[А-ЯA-Z][\x27а-яa-z]+(\040[А-ЯA-Z][\x27а-яa-z]+)?$/;
    if (!re.test(e.target.value)) {
      setFioError("Некорректное имя");
      setFioValid("");
    } else {
      setFioError("");
      setFioValid(classValidInput);
    }
  };

  const phoneHandler = (e) => {
    setPhone(e.target.value);
    const re = /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{10,11}$/;
    if (!re.test(e.target.value)) {
      setPhoneError("Некорректный нормер телефона");
      setPhoneValid("");
    } else {
      setPhoneError("");
      setPhoneValid(classValidInput);
    }
  };

  const blurHandler = (e) => {
    switch (e.target.name) {
      case "fio":
        setFioDirty(true);
        break;
      case "phone":
        setPhoneDirty(true);
        break;
      default:
    }
  };

  function onChange(value) {
    setIsCaptchaSuccess(true)
  }

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(serviceId, templateId, form.current, publicKey)
      .then((result) => {
      }, (error) => {
        console.log(error.text);
      });

    if (e.target.submit) {
      setIsOpen(false)
      submitComplete(true)
    }
  };

  return (
    <div
      className="send-form"
    >
      <div className="send-form__title">
        <img
          src={ person }
          alt="person"
          style={ { width: "32px", height: "32px" } }
        />
        <h1>Заявка на прохождение ТО</h1>
      </div>
      <form ref={form} onSubmit={sendEmail}>
        <label className="send-form__field-name">Фамилия Имя Отчество:
          { fioDirty && fioError && (
            <div className={ classError }>{ fioError }</div>
          ) }
        </label>
        <input
          onChange={ (e) => fioHandler(e) }
          value={ fio }
          onBlur={ (e) => blurHandler(e) }
          className={ fioDirty && fioError ? classInputError : classInput + fioValid }
          name="fio"
          type="text"
          placeholder="Иванов Иван Иванович"
          required
        />

        <label className="send-form__field-name">Номер телефона для связи:
          { phoneDirty && phoneError && (
            <div className={ classError }>{ phoneError }</div>
          ) }
        </label>
        <input
          onChange={ (e) => phoneHandler(e) }
          value={ phone }
          onBlur={ (e) => blurHandler(e) }
          className={ phoneDirty && phoneError ? classInputError : classInput + phoneValid }
          name="phone"
          type="text"
          placeholder="+7 (000) 000-00-00"
          required
        />
        <ReCAPTCHA
          sitekey={siteKey}
          onChange={onChange}
        />
        <input
          className="send-form__submit"
          type="submit"
          disabled={ !formValid || !isCaptchaSuccessful}
          value={ submitValue }
        />
      </form>
    </div>
  );
};
