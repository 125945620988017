import React, { useState } from "react";
import { SendForm } from "./SendForm";
import { Modal } from "react-rainbow-components";

export const RegButton = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [submitComplete, setSubmitComplete] = useState(false)

  return (
    <div>
      <button
        id="reg-button"
        className="reg-button"
        onClick={() => setIsOpen(true)}
      >
        Записаться на ТО
      </button>
      <Modal id="modal-1" isOpen={submitComplete} onRequestClose={()=>setSubmitComplete(false)}>
        <div>Заявка успешно отправлена, ожидайте звонка оператора</div>
      </Modal>
      <Modal id="modal-1" isOpen={isOpen}  onRequestClose={()=>setIsOpen(false)}>
      <SendForm  setIsOpen={()=>setIsOpen(false)} submitComplete={()=>setSubmitComplete(true)}/>
      </Modal>

    </div>
  );
};
